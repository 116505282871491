import React, { ReactNode } from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';

import { LinkStyle } from './ResumeContent';

export const ResumeLink = ({
  children,
  to,
  target = 'blank',
  ...props
}: {
  children: ReactNode;
  to: string;
  target?: string;
  props?: any;
}) => (
  <Link
    css={css`
      // TODO: 스타일 정리
      color: var(--color-text);
      text-decoration: underline;
      text-decoration-style: dashed;
      text-decoration-thickness: from-font;

      ${LinkStyle}
    `}
    to={to}
    target={target}
    {...props}
    className="resume-link"
  >
    {children}
  </Link>
);
