import React, { ReactNode } from 'react';

import { css } from '@emotion/react';
import { Grid, Link, Typography } from '@mui/material';

interface Project {
  title: string;
  titleLink?: string;
  subtitle: string;
  startDate?: string;
  endDate?: string;
  stacks: string[];
  summary: ReactNode;
  descriptions: ReactNode[];
}

const ResumeBlockquote = ({ children }: { children: ReactNode }) => (
  <blockquote
    css={css`
      background: var(--color-background-secondary);
      border-left: 0.3rem solid var(--color-border);
      margin: 0.5rem -0.5rem;
      padding: 1.5rem;
      color: var(--color-text);
      border-radius: 0.3rem;
      overflow: hidden;

      @media screen and (max-width: 900px) {
        margin: 1.2rem 0rem;
        padding: 1rem;
      }
    `}
  >
    <Typography variant="body1" lineHeight={1.8}>
      {children}
    </Typography>
  </blockquote>
);

const ResumeStack = ({ children }: { children: ReactNode }) => (
  <code
    css={css`
      display: inline-block;
      margin-right: 0.4rem;
      margin-bottom: 0.4rem;
      padding: 0.2rem 0.4rem;
      border-radius: 0.2rem;
      font-size: 0.85rem;
      background-color: var(--color-background-fourth);
      color: var(--color-code);
    `}
  >
    {children}
  </code>
);

export const ResumeProjects = ({
  logo,
  type,
  title,
  titleLink,
  description,
  role = 'Front-End Developer',
  startDate = '',
  endDate = '현재',
  projects = [],
}: {
  logo: ReactNode;
  type: 'company' | 'personal';
  title?: string;
  titleLink?: string;
  description?: ReactNode;
  role?: string;
  startDate?: string;
  endDate?: string;
  projects: Project[];
}) => {
  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={4}
      css={css`
        & + & {
          margin-top: 3rem;
        }
      `}
    >
      <Grid item xs={12} sm={'auto'}>
        {logo}
      </Grid>
      <Grid item xs mt={type === 'company' ? 2 : 0}>
        {type === 'company' && (
          <>
            {titleLink ? (
              <Link
                href={titleLink}
                target="_blank"
                variant="h5"
                css={css`
                  font-weight: 800;
                  text-underline-offset: 0.5rem;
                `}
              >
                {title}
              </Link>
            ) : (
              title
            )}
            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              mt={1}
            >
              <Grid item xs={12} sm>
                <Typography
                  component="p"
                  variant="subtitle1"
                  fontWeight={'bold'}
                >
                  {role}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  component="p"
                  variant="subtitle1"
                  fontWeight="bold"
                  css={css`
                    color: var(--color-text-secondary);
                  `}
                >
                  {startDate} ~ {endDate}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={1}>
              <ResumeBlockquote>{description}</ResumeBlockquote>
            </Grid>
          </>
        )}

        <Grid container>
          {projects.map(
            ({
              title,
              titleLink,
              subtitle,
              startDate,
              endDate,
              stacks,
              summary,
              descriptions,
            }) => (
              <Grid item mt={type === 'company' ? 3 : 0} key={title}>
                {titleLink ? (
                  <Link
                    href={titleLink}
                    target="_blank"
                    variant="h5"
                    css={css`
                      font-weight: 800;
                      text-underline-offset: 0.5rem;
                    `}
                  >
                    {title}
                  </Link>
                ) : (
                  <Typography
                    variant="h5"
                    fontWeight={'bold'}
                    css={css`
                      font-weight: 800;
                    `}
                  >
                    {title}
                  </Typography>
                )}

                <Grid container justifyContent={'space-between'} mt={1}>
                  <Grid item xs={12} sm>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      fontWeight={'bold'}
                    >
                      {subtitle}
                    </Typography>
                  </Grid>
                  {startDate && endDate && (
                    <Grid item>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        fontWeight={'bold'}
                        css={css`
                          color: var(--color-text-secondary);
                        `}
                      >
                        {startDate} ~ {endDate}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid container mt={2} mb={1}>
                  {stacks.map((stack) => (
                    <ResumeStack key={stack}>{stack}</ResumeStack>
                  ))}
                </Grid>

                <Grid container>
                  <ResumeBlockquote>{summary}</ResumeBlockquote>
                </Grid>

                <Grid container>
                  <ul
                    css={css`
                      line-height: 1.875;
                    `}
                  >
                    {descriptions.map((description, index) => (
                      <li key={index}>{description}</li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
