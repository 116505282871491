import React from 'react';

import { css } from '@emotion/react';
import { Grid, Typography } from '@mui/material';

import { KyungHeeLogo } from './ResumeLogos';
import { ResumeSection } from './ResumeSection';

export const ResumeEducations = () => {
  return (
    <ResumeSection title={'Educations'} description={'학력 및 교육'}>
      <Grid container rowSpacing={2} columnSpacing={4}>
        <Grid item xs={12} sm={'auto'}>
          <KyungHeeLogo />
        </Grid>
        <Grid item xs>
          <Grid container direction={'column'} justifyContent={'space-between'}>
            <Grid item>
              <Typography
                component="h6"
                variant="h6"
                css={css`
                  font-weight: bold;
                  margin-bottom: 0.2rem;
                `}
              >
                경희대학교 컴퓨터공학과 졸업
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                component="p"
                variant="subtitle1"
                css={css`
                  font-weight: bold;
                  margin-bottom: 0.4rem;
                `}
              >
                Undergraduate
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                component="p"
                variant="body1"
                css={css`
                  color: var(--color-text-secondary);
                `}
              >
                2015.02 ~ 2022.08
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ResumeSection>
  );
};
