import React, { ReactNode } from 'react';

import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';

export const ResumeSection = ({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children?: ReactNode;
}) => {
  return (
    <Box
      component="section"
      css={css`
        margin-top: 3rem;
      `}
    >
      <Typography
        component="h3"
        variant="h4"
        css={css`
          font-weight: 800;
          margin-bottom: 0.25rem;
        `}
      >
        {title}.
      </Typography>
      <Typography
        component="p"
        variant="subtitle1"
        css={css`
          font-weight: bold;
          color: var(--color-text-secondary);
          margin-bottom: 2rem;
        `}
      >
        {description}
      </Typography>

      {children}
    </Box>
  );
};
