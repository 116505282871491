import React from 'react';

import { ResumeLink } from './ResumeLink';
import { AblyLogo, NaverLogo, PetpeotalkLogo, YouVRLogo } from './ResumeLogos';
import { ResumeProjects } from './ResumeProjects';
import { ResumeSection } from './ResumeSection';

export const ResumeCareers = () => {
  return (
    <ResumeSection title={'Careers'} description={'경력 사항'}>
      <ResumeProjects
        type="company"
        logo={<NaverLogo />}
        title={'(주)네이버'}
        startDate="2022.09"
        titleLink="https://www.navercorp.com/"
        description={
          <>
            현재는 네이버의 해외 UGC 플랫폼 조직에서 신규 서비스를 개발하고
            있습니다. 이전에는 국내 UGC 플랫폼 개발을 담당하는 Apollo CIC에서
            네이버 엑스퍼트 서비스의 프론트엔드 개발을 담당했습니다.
          </>
        }
        projects={[
          {
            title: '네이버 엑스퍼트',
            titleLink: 'https://expert.naver.com/',
            subtitle: '전문가 매칭 플랫폼 서비스',
            startDate: '2022.09',
            endDate: '2023.04',
            stacks: ['React', 'TypeScript', 'Apollo GraphQL'],
            summary: (
              <>
                네이버 엑스퍼트에 입점한 전문가를 위한 대시보드 페이지, 고객을
                위한 엔드 페이지 개발에 참여하였습니다.
              </>
            ),
            descriptions: [
              <>
                랜딩 페이지, 검색 결과 페이지, 내 클래스 상세 페이지 등의
                페이지를 Apollo GraphQL 기반으로 마이그레이션
              </>,
              <>
                <ResumeLink
                  to="/2023/03/05/DRM-Contents-on-Web.html"
                  target="_blank"
                >
                  DRM 기능이 포함된 비디오 플레이어 라이브러리 적용
                </ResumeLink>
                을 통해 전사 기술 스택 통일 및 기술 부채 해결에 기여
              </>,
            ],
          },
        ]}
      ></ResumeProjects>

      <ResumeProjects
        type="company"
        logo={<PetpeotalkLogo />}
        title={'(주)펫페오톡'}
        startDate="2020.12"
        endDate="2021.06"
        titleLink="https://dogibogi.co.kr/"
        description={
          <>
            반려동물 모니터링 및 행동 분석 서비스 <strong>도기보기</strong>를
            운영하고 있는 <strong>(주)펫페오톡</strong>에서 근무했습니다.
            전반적인 프론트엔드 개발을 리드했고, 관련된 의사 결정 및 조직 문화
            개편에 적극적으로 참여했습니다.
          </>
        }
        projects={[
          {
            title: '도기보기',
            titleLink: 'https://dogibogi.co.kr/',
            subtitle: '반려동물 모니터링 및 행동 분석 결과 리포팅 서비스',
            startDate: '2020.12',
            endDate: '2021.06',
            stacks: [
              'Vue.js',
              'Vuex',
              'TypeScript',
              'AWS',
              'Chart.js',
              'Storybook',
            ],
            summary: (
              <>
                스마트폰을 이용해 실시간으로 반려동물을 모니터링하고, 수집된
                행동 분석 데이터를 시각화하여 보여주는 웹뷰 애플리케이션을
                제작하였습니다.
              </>
            ),
            descriptions: [
              <>
                AWS 인프라를 이용한{' '}
                <ResumeLink to="/2021/01/24/Introducing-WebRTC.html">
                  WebRTC 기반 양방향 미디어 통신 기능 구현
                </ResumeLink>{' '}
                및{' '}
                <ResumeLink to="/2020/11/15/SPA-hosting-via-AWS.html">
                  CI/CD 파이프라인 구축
                </ResumeLink>
                을 통해 제품 안정화 · 개발 프로세스 고도화에 기여
              </>,
              <>
                <ResumeLink to="/2021/07/02/Data-Visualization-with-Chart-js.html">
                  행동 인식 데이터를 Chart.js 기반으로 시각화하는 페이지를 구현
                </ResumeLink>
                하여 누적 다운로드 횟수를 1천 회에서 5천 회까지 증가시키고
                비즈니스 모델에 대한 검증을 진행
              </>,
              <>
                <ResumeLink to="/2020/12/13/Building-a-Organization-Culture-at-Startup-Company.html">
                  Notion과 G Suite 기반의 태스크 관리 및 조직 문화 개편
                </ResumeLink>
              </>,
            ],
          },
        ]}
      ></ResumeProjects>

      <ResumeProjects
        type="company"
        logo={<AblyLogo />}
        title={'(주)에이블리코퍼레이션'}
        startDate="2019.05"
        endDate="2020.11"
        titleLink="https://ably.team/"
        description={
          <>
            여성 패션 커머스 플랫폼 <strong>에이블리</strong>를 운영하고 있는
            <strong>(주)에이블리코퍼레이션</strong>에서 근무했습니다. 병역
            특례(정보처리 산업기능요원) 복무 도중 이직하였고 소집해제 이후에도
            근무했습니다.
          </>
        }
        projects={[
          {
            title: '에이블리 웹뷰',
            subtitle: '네이티브 앱 웹뷰 마이그레이션 프로젝트',
            startDate: '2020.12',
            endDate: '2021.06',
            stacks: [
              'Vue.js',
              'Vuex',
              'TypeScript',
              'SCSS',
              'Cypress',
              'Jest',
              'Storybook',
            ],
            summary: (
              <>
                네이티브 앱 내에서 보여지는 다양한 웹뷰 페이지들을 제작하고
                디자인 시스템을 관리하는 프로젝트에 참여했습니다.
              </>
            ),
            descriptions: [
              <>
                개인정보 수정, 편의점 결제 바코드, 결제 완료, 이벤트 페이지 등
                네이티브 앱 내에서 웹뷰로 보여주는 페이지를 구현하고 크로스
                브라우징 이슈를 대응
              </>,
              <>
                <ResumeLink to="/2020/11/29/Communicate-with-iframe-via-Message-Channel-API.html">
                  iframe으로 임베디드된 환경에서 페이지 간 통신을 위한 모듈 제작
                </ResumeLink>
              </>,
              <>
                Cypress와 Jest를 이용해 E2E · 유닛 테스트 코드 작성, E2E 테스트
                코드 커버리지는 90%까지 달성
              </>,
            ],
          },
          {
            title: '내 마켓 관리 어드민',
            titleLink: 'https://my.a-bly.com/',
            subtitle: '에이블리 셀러용 대시보드',
            startDate: '2019.05',
            endDate: '2020.11',
            stacks: ['Vue.js', 'Vuex', 'TypeScript', 'SCSS', 'ElementUI'],

            summary: (
              <>
                에이블리 입점 셀러를 위한 대시보드 페이지의 신규 기능을 추가하고
                유지보수하는 프로젝트에 참여했습니다.
              </>
            ),
            descriptions: [
              <>
                회원 가입, 아이디 · 비밀번호 찾기, 물품 사입, 재고와 배송 관리,
                정산 등 커머스 판매자 관련 비즈니스 로직 구현
              </>,
              <>
                <ResumeLink to="/2019/09/08/Higher-Order-Component-pattern-in-Vue.html">
                  고차 컴포넌트(HOC)를 활용해 권한에 따라 다른 컴포넌트를 렌더링
                </ResumeLink>
              </>,
              <>
                기존 2개(N&#215;M)로 고정되어 있던 상품 별 옵션을 무제한으로
                선택 가능하도록 UI와 자료구조를 개편하여, 패션에만 한정되어 있던
                사업 분야를 핸드메이드 · 뷰티 등으로 확장할 수 있도록 기여
              </>,
            ],
          },
          {
            title: '에이블리 운영 어드민',
            subtitle: '백오피스 웹 애플리케이션',
            startDate: '2019.05',
            endDate: '2020.11',
            stacks: [
              'Vue.js',
              'Vuex',
              'TypeScript',
              'SCSS',
              'ElementUI',
              'Jest',
              'PHP',
            ],
            summary: (
              <>
                임직원들을 위한 어드민 페이지를 신규 기술 스택으로
                마이그레이션했습니다.
              </>
            ),
            descriptions: [
              <>
                상품 조회, 셀러 조회, 세일 일정 등록, 이벤트 생성, 우수 셀러
                관리, 쿠폰 발급 등의 커머스 운영 관련 비즈니스 로직 구현
              </>,
              <>
                PHP 기반 코드 베이스를 Vue.js와 TypeScript 기반으로 마이그레이션
              </>,
            ],
          },
        ]}
      ></ResumeProjects>

      <ResumeProjects
        type="company"
        logo={<YouVRLogo />}
        title={'(주)유브이알'}
        startDate="2018.02"
        endDate="2019.05"
        titleLink="https://www.3i.ai/"
        description={
          <>
            VR 가상 투어 솔루션 <strong>YouVR</strong>을 운영하는{' '}
            <strong>(주)유브이알</strong>에서 근무했습니다. 병역 특례(정보처리
            산업기능요원) 신분으로 복무를 시작했습니다.
          </>
        }
        projects={[
          {
            title: 'YouVR',
            titleLink: 'https://www.beamo.ai/ko/',
            subtitle: 'VR 매물 관리 웹 애플리케이션',
            startDate: '2018.02',
            endDate: '2019.05',
            stacks: ['Vue.js', 'Vuex', 'iView', 'Leaflet', 'AngularJS'],
            summary: (
              <>
                360° 카메라를 이용해 제작된 VR 매물 관리 플랫폼을 유지보수하고
                새로운 기술 스택으로 마이그레이션하는 프로젝트에 참여했습니다.
              </>
            ),
            descriptions: [
              <>
                매물을 전반적으로 관리하는 대시보드, 매물을 편집하는 에디터,
                매물을 관람할 수 있는 뷰어 등의 웹 애플리케이션 구현
              </>,
              <>AngularJS 기반 코드 베이스를 Vue.js로 마이그레이션</>,
              <>
                <ResumeLink to="/2019/06/06/Refactoring-360-panorama-viewer.html">
                  뷰어 반응형 디자인 리팩토링을 통해 유지보수성 개선
                </ResumeLink>
              </>,
            ],
          },
        ]}
      ></ResumeProjects>
    </ResumeSection>
  );
};
