import React, { ReactNode } from 'react';

import { css } from '@emotion/react';
import { ImageFormat, StaticImage } from 'gatsby-plugin-image';

const formats: ImageFormat[] = ['auto', 'webp', 'avif'];

const ImageWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div
      css={css`
        display: inline-block;
        border-radius: 0.5rem;
        overflow: hidden;
        background-color: var(--white100);
        box-shadow: 0 0.1em 0.3em var(--color-box-shadow);
        border-color: var(--color-background);
      `}
    >
      {children}
    </div>
  );
};

export const AblyLogo = () => {
  return (
    <ImageWrapper>
      <StaticImage
        layout="fixed"
        formats={formats}
        src="./assets/ably.png"
        width={100}
        height={100}
        quality={100}
        alt="에이블리"
      />
    </ImageWrapper>
  );
};

export const BambooLogo = () => {
  return (
    <ImageWrapper>
      <StaticImage
        layout="fixed"
        formats={formats}
        src="./assets/bamboo.png"
        width={100}
        height={100}
        quality={100}
        alt="대나무숲"
      />
    </ImageWrapper>
  );
};

export const MyLogo = () => {
  return (
    <ImageWrapper>
      <StaticImage
        layout="fixed"
        formats={formats}
        src="./assets/me.png"
        width={100}
        height={100}
        quality={100}
        alt="재그지그의 개발 블로그"
      />
    </ImageWrapper>
  );
};

export const NaverLogo = () => {
  return (
    <ImageWrapper>
      <StaticImage
        layout="fixed"
        formats={formats}
        src="./assets/naver.png"
        width={100}
        height={100}
        quality={100}
        alt="네이버"
      />
    </ImageWrapper>
  );
};

export const PetpeotalkLogo = () => {
  return (
    <ImageWrapper>
      <StaticImage
        layout="fixed"
        formats={formats}
        src="./assets/petpeotalk.png"
        width={100}
        height={100}
        quality={100}
        alt="펫페오톡"
      />
    </ImageWrapper>
  );
};

export const KyungHeeLogo = () => {
  return (
    <ImageWrapper>
      <StaticImage
        layout="fixed"
        formats={formats}
        src="./assets/kyunghee.png"
        width={100}
        height={100}
        quality={100}
        alt="경희대학교"
      />
    </ImageWrapper>
  );
};

export const YouVRLogo = () => {
  return (
    <ImageWrapper>
      <StaticImage
        layout="fixed"
        formats={formats}
        src="./assets/youvr.png"
        width={100}
        height={100}
        quality={100}
        alt="YouVR"
      />
    </ImageWrapper>
  );
};
