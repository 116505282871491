import React from 'react';

import { css } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';

import { SmallDivider } from '@/pages/manifesto';

import { ResumeActivities } from './ResumeActivities';
import { ResumeCareers } from './ResumeCareers';
import { ResumeEducations } from './ResumeEducations';
import { ResumeOpenSources } from './ResumeOpenSources';
import { BioContacts } from '../Bio/BioContacts';

export const LinkStyle = css`
  a.MuiLink-root,
  a.resume-link {
    text-underline-offset: 0.45em;
    text-decoration-color: var(--grey600);

    ::after {
      content: ' ↗ ';
      font-size: 80%;
      font-weight: normal;
      vertical-align: middle;
    }
  }
`;

export const ResumeContent = () => {
  return (
    <Box
      css={css`
        padding-top: 3rem;
        padding-bottom: 6rem;
      `}
    >
      <Typography
        component="h2"
        variant="h3"
        css={css`
          line-height: 1.4;
          word-break: keep-all;
        `}
      >
        안녕하세요
        <br />
        저는{' '}
        <strong
          css={css`
            strong {
              font-weight: 800;
            }
          `}
        >
          정종윤
        </strong>
        입니다
      </Typography>

      <blockquote
        css={css`
          background: var(--color-background-secondary);
          border-left: 0.5rem solid var(--color-border);
          margin: 2.5rem -1.5rem;
          padding: 1.5rem 1.5rem;
          color: var(--color-text);
          border-radius: 0.5rem;
          overflow: hidden;
          word-break: keep-all;

          @media screen and (max-width: 900px) {
            margin: 2.5rem 0rem;
          }
        `}
      >
        <Typography component="p" variant="subtitle1">
          4년차 프론트엔드 개발자로, 온라인에서는 재그지그라는 닉네임으로
          활동하고 있습니다.
        </Typography>
        <br />
        <Typography component="p" variant="subtitle1">
          병역 특례(정보처리 산업기능요원) 제도를 통해 처음으로 개발자 커리어를
          시작하였으며, 시드 단계의 스타트업부터 대기업에 이르기까지 다양한
          규모의 회사에서 넓은 도메인을 경험하며 프론트엔드 개발에 대한 전문성을
          쌓았습니다.
        </Typography>
        <br />
        <Typography component="p" variant="subtitle1">
          <strong>개발자로서의 전문성</strong> ·{' '}
          <strong>효율적인 커뮤니케이션</strong> ·{' '}
          <strong>성장에 대한 끊임없는 동기부여</strong>라는 세 가지 가치를
          추구하며, 이를 바탕으로 비즈니스의 성공에 능동적으로 기여하는 개발자가
          되고자 합니다.
        </Typography>
      </blockquote>

      <Grid container item justifyContent={'center'}>
        <BioContacts></BioContacts>
      </Grid>

      <Grid
        container
        css={css`
          ${LinkStyle}
        `}
      >
        <SmallDivider></SmallDivider>

        <ResumeCareers></ResumeCareers>
        <ResumeOpenSources></ResumeOpenSources>
        <ResumeActivities></ResumeActivities>
        <ResumeEducations></ResumeEducations>
      </Grid>
    </Box>
  );
};
