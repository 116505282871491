import React from 'react';

import { Link } from '@mui/material';

import { ResumeLink } from './ResumeLink';
import { BambooLogo, MyLogo } from './ResumeLogos';
import { ResumeProjects } from './ResumeProjects';
import { ResumeSection } from './ResumeSection';

export const ResumeOpenSources = () => {
  return (
    <ResumeSection title={'Open Sources'} description={'개인 프로젝트'}>
      <ResumeProjects
        type="personal"
        logo={<MyLogo />}
        projects={[
          {
            title: '재그지그의 개발 블로그',
            titleLink: 'https://wormwlrm.github.io/',
            subtitle: '개인 기술 블로그',
            stacks: [
              'React',
              'TypeScript',
              'Gatsby',
              'GraphQL',
              'MUI',
              'Emotion',
            ],
            summary: (
              <>
                GitHub Page에 개인적인 프론트엔드 개발 경험을 기반으로 한 기술
                블로그를 6년째 운영하고 있습니다.
              </>
            ),
            descriptions: [
              <>
                <ResumeLink to="/2023/05/07/SEO-for-Technical-Blog.html#show-me-your-seo">
                  누적 방문자수 70만, 월간 방문자수 1.6만
                </ResumeLink>
              </>,
              <>
                일부 포스트는{' '}
                <Link
                  href="https://github.com/search?q=repo%3Anaver%2Ffe-news+wormwlrm.github.io&type=code"
                  target="_blank"
                >
                  네이버
                </Link>
                ,{' '}
                <Link
                  href="https://github.com/search?q=repo%3Achannel-io%2Fmonthly-channel%20wormwlrm.github.io&type=code"
                  target="_blank"
                >
                  채널코퍼레이션
                </Link>{' '}
                등의 IT 기업에서 큐레이션 포스트로 선정
              </>,
              <>
                <ResumeLink to="/2022/02/25/Migration-Journey-from-Jekyll-to-Gatsby.html">
                  Ruby, Jekyll 기반 코드 베이스를 TypeScript, React, Gatsby로
                  마이그레이션
                </ResumeLink>
              </>,
            ],
          },
        ]}
      ></ResumeProjects>

      <ResumeProjects
        type="personal"
        logo={<BambooLogo />}
        projects={[
          {
            title: '대나무숲',
            titleLink: 'https://github.com/wormwlrm/bamboo-forest',
            subtitle: '익명 메시지 전송 슬랙 봇',
            stacks: ['AWS', 'TypeScript', 'Bolt.js'],
            summary: (
              <>
                300명 규모의 개발자 커뮤니티에서 사용하기 위한 익명 메시지 전송
                슬랙 봇을 제작하였습니다.
              </>
            ),
            descriptions: [
              <>
                <ResumeLink to="/2022/05/07/Bamboo-Forest-Slack-App.html">
                  기존에 존재하는 익명 메시지 전송 솔루션이 유료 요금제여서,
                  이를 대체하는 봇을 직접 구현하는 방식으로 개발자 커뮤니티에
                  기여
                </ResumeLink>
              </>,
              <>
                <ResumeLink to="/2023/02/12/Deploy-Slack-App-with-AWS-Lambda.html">
                  사용량 최적화를 위해 인스턴스에서 서버리스(AWS Edge) 형태로
                  마이그레이션
                </ResumeLink>
              </>,
            ],
          },
        ]}
      ></ResumeProjects>
    </ResumeSection>
  );
};
