import React from 'react';

import { css } from '@emotion/react';
import { Grid, Link as MuiLink, Typography } from '@mui/material';

import { ResumeLink } from './ResumeLink';
import { ResumeSection } from './ResumeSection';

const activities = {
  2023: [
    <>
      <MuiLink
        href="http://www.yes24.com/Product/Goods/118547714"
        target="_blank"
      >
        『그림과 작동 원리로 쉽게 이해하는 웹의 기초(위키북스)』
      </MuiLink>{' '}
      부록 집필
    </>,
    <>한국대학생IT경영학회(KUSITMS) 외부 발표</>,
  ],
  2022: [
    <>
      <ResumeLink
        to="/2022/10/10/KHU-Tech-Concert-Presentation.html"
        target="_blank"
      >
        경희대학교 컴퓨터공학과 테크콘서트 외부 발표
      </ResumeLink>
    </>,
    <>
      <MuiLink
        href="https://www.dbpia.co.kr/journal/articleDetail?nodeId=NODE11036032"
        target="_blank"
      >
        한국소프트웨어종합학술대회 『HTML5 Canvas 기반 오픈소스 이미지 에디터
        라이브러리 개발』 입상
      </MuiLink>
    </>,
  ],

  2021: [
    <>
      <MuiLink
        href="http://www.yes24.com/Product/Goods/103521543"
        target="blank"
      >
        『나는 주니어 개발자입니다(로드북)』 집필
      </MuiLink>
    </>,
  ],
  2020: [
    <>
      <ResumeLink
        to="/2020/01/11/Review-of-hackathon-mentor.html"
        target="_blank"
      >
        고려대학교 해커톤 KoreaHacks 프론트엔드 멘토
      </ResumeLink>
    </>,
  ],
};

const sortedActivities = Object.entries(activities).sort(
  ([a], [b]) => Number(b) - Number(a)
);

export const ResumeActivities = () => {
  return (
    <ResumeSection title={'Activities'} description={'외부 활동'}>
      {sortedActivities.map(([year, activity], index) => (
        <Grid container key={index} mb={2}>
          <Grid item>
            <Typography component="h6" variant="h6">
              <strong>{year}</strong>
            </Typography>
            <ul
              css={css`
                line-height: 2;
                margin: 0.5rem 0;
              `}
            >
              {activity.map((item, index) => (
                <li key={index}>
                  <Typography variant="subtitle1" component="p">
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      ))}
    </ResumeSection>
  );
};
